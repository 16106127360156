/* www.hiof.no x1 local.less */

// X-styles
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

:root {
  --color-primary: #347e84;
  --color-link: #237379; // Slightly darkened color-primary for better uu (in case of bg color)
  --color-breadcrumb: #00483C;
  --color-nav-links: #E9F6F4;
  --color-aquablue: #E1ECED;
  --color-sjgrnn-dark: #00483C;
  --color-sjgrnn-15: #E9F6F4;
  --color-sjgrnn-45: #BEE4DD;
  --color-korall-45: #EDC2BB;
  --color-korall-15: #F9EBE9;
  --color-korall-dark: #510000;
  --color-dark: #23201F;
  --color-varmgr-15: #F7F6F5;
  --color-varmgr-45: #e6e4e2;
  --color-aksentfarge1: #F3F7F8;
  --color-date-hex: #E9F6F4;
  --color-avd: #E9F6F4;
  --color-alt-row: #F7F6F5;
  --color-button: #347e84;
  --color-button-hover: #00483C;
  --color-restricted: #CB4D3A;
  --color-crisis: #CB4D3A;
  --space-xs: 10px;
  --space-s: 20px;
  --space-m: 40px;
  --space-l: 80px;
  --border-radius: 8px;
  --img-top: url("../images/mountains.svg");
  --img-pattern: url("../images/pattern.png");
  --img-footer: url("../images/footer.png");
  --img-date-bg: url("../images/hexagon.svg");
  --img-button-arrow-white: url("../images/arrow-white.svg");
  --img-button-arrow: url("../images/arrow.svg");
  --img-search: url("../images/search-white.svg");
  --img-menu: url("../images/menu-white.svg");
  --img-search-inv: url("../images/search.svg");
  --img-menu-inv: url("../images/menu.svg");
  --img-bullet-icon: url("../images/bullet-icon.svg");
  --img-location-pin: url("../images/location-pin.svg");
  --img-caret-down: url("../images/caret-down.svg");
  --img-info: url("../images/alert.svg");
  --img-quote: url('../images/quote.svg');
  --img-alert: url("../images/warning-triangle.svg");
  --width-total-max: 1440px;
  --width-max: 1100px;
  --width-soft-max: 1040px;
  --width-text-max: 650px;
}

// Local imports
@import "fonts.less";
@import "subsection.less";

@import "autocomplete.less"; // Should probably be moved to X common less file
                             // (and overrides put in local file, if any)

// General

body {
  font-family: "Source Sans Pro", sans-serif;
  //font-size: 1.8rem;
}

@media (@screen-medium) {
  h1 {
    font-size: 4.5rem;
  }
  h2 {
    font-size: 3.8rem;
    font-weight: 600;
  }
  h3 {
    font-size: 3.1rem;
  }
  h4 {
    font-size: 2.6rem;
  }
  h5 {
    font-size: 2.2rem;
  }
}
@media (@screen-below-medium) {
  h1 {
    font-size: 3.8rem;
  }
  h2 {
    font-size: 3.1rem;
  }
  h3 {
    font-size: 2.6rem;
  }
  h4 {
    font-size: 2.2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
}

// x-candidate

// If you need to remove margin between two frontpage-boxes, add: row-all-no-margin-bottom to row
.grid-container.row-all-colored.row-all-no-margin-bottom {
  margin-bottom: 0;
}

.grid-container {
  margin-bottom: var(--space-l);
  margin-top: var(--space-m);
  &.row-all-colored {
    margin-top: 0;
  }
}

#main .vrtx-tag-cloud {
  padding-left: 0;
  li {
    display: inline-block;
    margin: 0 20px 5px 0;

    &:before {
      display: none;
    }
    &.tag-magnitude-1 { font-size: 1.4rem; }
    &.tag-magnitude-2 { font-size: 1.6rem; }
    &.tag-magnitude-3 { font-size: 1.8rem; }
    &.tag-magnitude-4 { font-size: 2.0rem; }
    &.tag-magnitude-5 { font-size: 2.2rem; }
  }
}

// Denne finnes for innholdsfortegnelse, toc, men burde den ikke vært sånn for alle lister?
ul ul {
  margin-bottom: 0;
}
// x-candidate ^

#crisis-banner, #crisis-banner-demo {
  background-color: var(--color-crisis);
  .crisis-banner-inner {
    width: fit-content;
    max-width: 100vw;
    margin: 0 auto;
    padding-left: 50px;
    position: relative;
    &::before {
      background: var(--img-alert) no-repeat center;
      width: 1.7em;
      height: 1.7em;
      top: -2px;
      position: absolute;
      left: 0px;
    }
  }
}

main a {
  color: var(--color-link);
}

.vrtx-article-body h2 {
  margin: var(--space-m) 0 20px;
}

.vrtx-feed .authors, .vrtx-feed .published-date {
  font-size: var(--font-size-base);
  color: black;
  margin-bottom: var(--space-xs);
}
.vrtx-feed .item-title {
  margin-bottom: var(--space-xs);
}


#breadcrumbs {
  font-size: 1.6rem;
  #vrtx-breadcrumb-wrapper {
    margin-bottom: 0;
    &:has(.vrtx-breadcrumb-before-active) {
      padding-top: var(--space-s);
      margin-bottom: var(--space-s);
    }
  }
  a {
    color: var(--color-link);
    text-decoration: none;
  }
}

#vrtx-collections {
  display: none;
}

@media (@screen-medium) {
  #vrtx-collection {
    .vrtx-resource {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0 20px;
      position: relative;
      .lastModified, .introduction  {
        grid-column: 2 / 3;
      }
    }
  }
}

#vrtx-collection .vrtx-icon {
  background: url(/vrtx/dist/resources/x1/images/document-icon.svg) no-repeat;
  background-size: cover;
  width: 40px;
  min-width: 40px;
  height: 53px;
  display: inline-block;
}

#vrtx-collection .vrtx-icon.pdf::after {
  color: var(--color-dark);
  font-size: 1.4rem;
  width: 40px;
  height: 19px;
  font-weight: 600;
  text-align: center;
  top: 25px;
  position: absolute;
  font-variant: small-caps;
}

#vrtx-collection .vrtx-icon.pdf::after {
  content: "pdf";
}
#vrtx-collection .vrtx-icon.doc::after {
  content: "doc";
}
#vrtx-collection .vrtx-icon.xls::after {
  content: "xls";
}
#vrtx-collection .vrtx-icon.ppt::after {
  content: "ppt";
}
#vrtx-collection .vrtx-icon.img::after {
  content: "img";
}
#vrtx-collection .vrtx-icon.txt::after {
  content: "txt";
}
#vrtx-collection .vrtx-icon.html::after {
  content: "html";
}

.main {
  #submenu {
    max-width: var(--width-total-max);
    margin: 0 auto;
  }
  .vrtx-subfolder-menu {
    background-color: var(--color-varmgr-15);
    padding: 20px 20px 13px;
    border-radius: var(--border-radius);
     a {
      font-weight: 600;
      &::before {
        background: var(--img-button-arrow) no-repeat center;
        background-size: 100%;
      }
    }
  }
}

#vrtx-frontpage {
  .main #submenu {
    padding-left: 50px;
    padding-right: 50px;
  }
}

 .vrtx-introduction {
  font-size: 2.6rem;
  margin-bottom: var(--space-m);
}
.vrtx-introduction-image {
  margin-bottom: var(--space-m);
}
// Header

// language select
// on small screen, hide language select
// display it inside main menu when this is open
@media (@screen-below-medium) {
  #header-tools {
    grid-template-columns: auto auto;
    #header-language {
      // faux position inside menu
      position: absolute;
      right: 0;
      top: 101px;
      transition: all .5s;
      > a, > span {
        padding: 4px;
      }
    }
  }

  // hide lang select unless main menu is open
  body:not(.sidebar-menu-wrapper-visible) {
    #header-tools {
      #header-language {
        display: none;
      }
    }
  }

  // when search open, move lang select down
  body.header-search-visible {
    #header-tools {
      #header-language {
        margin-top: 85px;
      }
    }
  }
}

#head-wrapper {
  background-color: var(--color-primary);
  color: white;
  padding: 7px 0;
  @media (@screen-medium) {
    padding: 25px 0;
  }
  #header-tools {
    background-color: var(--color-primary);
    border-radius: var(--border-radius);
    padding: 10px;
    margin-left: auto;
    .search-form form {
      input {
        font-size: 1.8rem;
        font-weight: 400;
        border-radius: var(--border-radius);
        border: 2px solid white;
      }
      button {
        font-size: 1.8rem;
        background: white;
        color: var(--color-link);
        font-weight: 600;
      }
    }
    #header-language {
      >a {
        font-size: 1.5rem;
        font-weight: 700;
      }
      >span {
        font-size: 1.5rem;
        font-weight: 700;
        border: 2px solid;
        padding: 0 8px;
        border-radius: var(--space-xs);
      }
    }
    .toggle-search::before {
      background-image: var(--img-search);
    }
    .sidebar-menu-toggle::before {
      background-image: var(--img-menu);
      width: 30px;
    }
    .toggle-search, .sidebar-menu-toggle {
      font-size: 1.6rem;
      font-weight: 700;
    }

  }
  .logo {
    background-image: url("../images/logos/logo-no.svg");
    background-repeat: no-repeat;
    width: 295px;
    height: 50px;
    @media (@screen-below-medium) {
      height: var(--space-m); // width will adapt to available space
    }
    // Needed due to HTML difference
    a {
      width: 100%;
      height: 100%;
      text-indent: -100000px;
    }
  }
}
html:has(.light-top) #head-wrapper {
  color: var(--color-dark);
  #header {
    .logo {
      background-image: url("../images/logos/logo-no-black.svg");
    }
    .toggle-search::before {
      background-image: var(--img-search-inv);
    }
    .sidebar-menu-toggle::before {
      background-image: var(--img-menu-inv);
      width: 30px;
    }
  }
}

html[lang=en] #header .logo {
  background-image: url("../images/logos/logo-en.svg");
}
html[lang=en]:has(.light-top) #header .logo {
  background-image: url("../images/logos/logo-en-black.svg");
}


#header-search-form {
  input[type="text"]::placeholder {
    color: (var(--color-sjgrnn-15));
  }
}

// Main menu

#main-menu {
  background-color: var(--color-primary);
  .sidebar-menu {
    background-color: inherit;
  }
  * {
    color: white;
  }
}

.sidebar-menu-wrapper {
  a {
    padding: 0 30px 3px 0;
  }
  .vrtx-tab-menu {
    a {
      @media (@screen-medium) {
        font-size: 3.1rem;
        font-weight: 600;
      }
    }
  }
  .sidebar-global-menu {
    border: none;
    padding: 25px 0 50px
  }

  #supplementary-menu {
    display: block;
    min-width: 180px;
  }
}


#vrtx-searchview {
  h1 {
    margin-top: 30px;
  }
  .vrtx-search-restricted {
    color: var(--color-restricted);
  }
}

// Buttons, links etc.

a {
  text-decoration-thickness: 1px;
  text-underline-offset: .07em;
}

input[type=text], button, select {
  height: 2.8em;
  padding: 0.7em 0.9em;
  font-size: 2.2rem;
  border-radius: var(--border-radius);
  border: 2px solid var(--color-primary);
}

.vrtx-big-search fieldset {
  display: flex;
  gap: var(--space-s);
}

//fake button

.button {
  background-color: transparent;
  padding: 0.8em 3.3em 0.8em 1em;
  border-width: 3px;
  &::after {
    background-image: var(--img-button-arrow);
    width: 1.1em;
    height: 1.1em;
    top: 1.1em;
  }
}

.button-large, .button-loud, .button-loud-korall {
  background-color: var(--color-button);
  border-width: 3px;
  padding: 0.8em 3.3em 0.8em 1em;
  text-decoration: none;
  &::after {
    background-image: var(--img-button-arrow-white);
    width: 1.1em;
    height: 1.1em;
    top: 1.1em;
  }
}
.button-loud-korall  {
  background-color: var(--color-korall-dark);
  color: white;
  display: inline-block;
  position: relative;
  border: 3px solid transparent;
  border-radius: var(--radius);
  &::after {
    background: var(--img-button-arrow-white) no-repeat center;
    content: "";
    background-size: contain;
    position: absolute;
    right: 1.1em;
    transition: all 0.3s;
  }
  &:hover::after{
    right: 5px;
  }
}
// real button
#main button, #vrtx-searchview .vrtx-big-search button {
  color: white;
  background-color: var(--color-primary);
  white-space: nowrap;
 // margin-left: var(--space-xs);
  border: none;
  padding: 0.7em 1em;
  font-size: 2.2rem;
  &:hover, &:focus {
    background-color: var(--color-button-hover);
  }
}
// button in admin-view
#main .vrtx-edit-row  {
  button {
    &:hover, &:focus {
      color: white !important;
    }
  }
}
.vrtx-search-form, #vrtx-search-container {
  margin-bottom: 25px;
  display: flex;
  gap: var(--space-xs);
  max-width: 620px;
  input#person-search {
    flex-grow: 1;
    border-radius: 5px;
    font-size: 2.2rem;
    padding: calc(var(--space-s) - 6px);
    border: 2px solid var(--color-primary);
  }
}
#vrtx-searchview .vrtx-big-search {
  input#bigSearchInputField {
    border-radius: 5px;
    font-size: 2.2rem;
    padding: calc(var(--space-s) - 6px);
    border: 2px solid var(--color-primary);
  }
}
#vrtx-search-container {
  flex-direction: column;
}

.read-more,
.vrtx-more a,
.vrtx-read-more,
.all-messages {
  font-weight: normal;
  &::before {
    background-color: var(--color-primary);
    background-image: var(--img-button-arrow-white);
    background-size: auto; // Use size defined in svg
    display: inline-block;
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    position: relative;
    top: .35em;
  }
}

details.accordion-wrapper {
  border-bottom: 2px solid var(--color-varmgr-45);
  margin-bottom: 0px;
  summary {
      margin-bottom: 0;
      width: 100%;
      h2.accordion {
          font-size: 2.8rem;
          margin: 0;
      }
      h3.accordion {
          font-size: 2.2rem;
      }
      h4.accordion {
          font-size: 1.8rem;
      }
      .accordion {
          width: auto;
          padding: 12px 1em 10px 0;
          margin-bottom: 0;
          color: var(--color-link);
          &::before {
              display: none;
          }
          &::after {
              content: var(--img-caret-down);
              right: 0;
              top: 0.5em;
              position: absolute;
          }
      }
      &:hover {
          text-decoration: none;
          text-decoration-color: transparent ;
      }
      p {
          padding-bottom: 20px;
      }
  }

  &[open] summary .accordion::after {
    content: var(--img-caret-down);
    rotate: 180deg;
  }
}
.accordion-end {
  margin-top: 15px;
}

// frontpage

.the-frontpage #breadcrumbs:has(.vrtx-breadcrumb-before-active), #vrtx-frontpage:has(h1) #breadcrumbs {
  padding-bottom: var(--space-m);
}
.the-frontpage #breadcrumbs {
  display: none;
}
.simfredrikstad.the-frontpage #breadcrumbs {
  display: block;
}
.simfredrikstad.the-frontpage #breadcrumbs:has(.vrtx-breadcrumb-before-active), .simfredrikstad#vrtx-frontpage:has(h1) #breadcrumbs {
  padding-bottom: 0;
}

#vrtx-frontpage:has(.background-video), #vrtx-frontpage:has(.media-wide) {
  #breadcrumbs {
    padding-bottom: 0;
  }
}
.the-frontpage.for-ansatte #submenu {
  padding-top: var(--space-m);
}
.the-frontpage h1.hidden + #vrtx-main-content #vrtx-main-content-1.navigation-links {
  padding-top: var(--space-l);
}


body#vrtx-frontpage:has(.page-top) {
  #header-tools {
    #header-language, .header-search-expand, .sidebar-menu-toggle {
      background-color: var(--color-primary);
    }
  }
  header#head-wrapper {
    background: transparent;
  }
  &.sidebar-menu-wrapper-visible, &.header-search-visible  {
    header#head-wrapper {
      background-color: var(--color-primary);
    }
  }
  #breadcrumbs {
    display: none;
  }
}


// If box has vrtx-more remove link decoration
.vrtx-frontpage-box:has(.vrtx-more) h2 a {
  color: var(--color-dark);
  text-decoration: none;
}

.grid-container {
  &.no-margin-bottom, &.row-all-colored.no-margin-bottom {
    margin-bottom: 0;
  }
}
.vrtx-edit-content {
  .grid-container.media-wide.transparent-top img {
    margin-top: 0;
  }
}
// vrtx-frontpage-box specificity added to X to prevent class working on grid-container

.row:has(.box-special, .img-special-left, .img-special-right){
  @media (@screen-below-medium) {
    padding: 0;
  }
}
.vrtx-frontpage-box {
  &.box-special {
    border-radius: var(--border-radius);
    h2 {
      font-size: 3.8rem;
    }
    p {
      font-size: 2.6rem;
    }
  }
  &.img-special-left,
  &.img-special-right,
  &.box-special {
    background: var(--color-varmgr-15);
    &.korall {
      background: var(--color-korall-45);
      color: var(--color-korall-dark);
      ul li::marker,
      .read-more,
      .vrtx-more a,
      .vrtx-read-more,
      .all-messages {
        color: var(--color-korall-dark);
        &::before {
          background-color: var(--color-korall-dark);
        }
      }
    }
    &.sjogronn {
      background: var(--color-sjgrnn-45);
      color: var(--color-sjgrnn-dark);
      .read-more,
      .vrtx-more a,
      .vrtx-read-more,
      .all-messages {
        color: var(--color-sjgrnn-dark);
      }
    }
    img {
      aspect-ratio: 4/3;
    }
   .vrtx-box-content {
    padding: var(--space-l);
    padding-bottom: calc(var(--space-l) - 25px);
    @media (@screen-below-medium) {
      padding: 15px;
    }
   }
  }

  &.link-special {
    padding: var(--space-l);
    border-radius: var(--border-radius);
    border: 2px solid var(--color-primary);
    .vrtx-box-content {
      @media (@screen-large) {
        font-size: 3.8rem;
      }
      a::after {
        background: var(--color-primary) var(--img-button-arrow-white) no-repeat center;
        background-size: auto;
        width: 0.8em;
        height: 0.8em;
        margin-left: 0.4em;
        margin-top: 0.25em;
      }
    }
  }
}
.grid-container {
  &.media-wide, .media-wide, &.background-video, .background-video {
    margin-top: 0;
    &.pos-top img {
      object-position: top;
    }
    &.pos-bottom img {
      object-position: bottom;
    }
    &.pos-left img {
      object-position: top;
    }
    &.pos-right img {
      object-position: right;
    }

    @media (@screen-large) {
      .vrtx-box-content {
        background-color: white;
        color: var(--color-sjgrnn-dark);
        border-radius: var(--border-radius);
      }
    }
    .text-box, .text-box-left {
      border-radius: var(--border-radius);
    }
    .text-box-left {
      background: white;
      @media (@screen-large) {
        position: absolute;
        bottom: 50px;
        right: 50%;
        width: 500px;
        padding: 30px;
      }
    }
  }
}

#vrtx-frontpage {
  .grid-container {
    &.row-all-colored, &.row-all-colored.grid-color-grey {
        background: var(--color-varmgr-15);
        &:last-child {
          margin-bottom: -100px;
      }
    }
    // general limit to text width
    p {
        max-width: var(--width-soft-max);
    }
  }
}

// frontpage ^

#total-main .grid-container.row-all-colored:last-child {
  margin-bottom: -100px;
}

// spesial

// Enable background-video
.vrtx-background-video {
  &::before {
    display: none;
  }
  video {
    opacity: 1;
  }
}

#vrtx-structured-article,
#vrtx-structured-event {
  // Fullscreen media (using image-center class)
  .vrtx-article-body p.image-center,
  .vrtx-article-body .image-center figure {
    img,
    video {
      @media (@screen-large) {
        margin-left: -200px;
      }
      @media (@screen-oversized) {
        margin-left: calc(525px - 50vw);
      }
    }
  }
}

.two-column {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  clear: left;
}
.four-column {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  clear: left;
  &.round-img img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 100%;
  }

}
// spesial ^

blockquote {
  margin: 0;
  padding-left: 62px;
  position: relative;
  &::before {
      content: "";
      position: absolute;
      width: 2.5em;
      left: 0;
      background: var(--img-quote) no-repeat;
  }
  p:first-child {
      font-size: 2.6rem;
      font-weight: 400;
      line-height: 150%;
  }
}
#main {
  .vrtx-article-body .vrtx-facts-container, .vrtx-facts-container {
    background: var(--color-varmgr-15);
    font-size: var(--font-size-small);
    color: var(--color-dark);
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: var(--border-radius);
    ul li::marker {
      color: var(--color-dark);
      font-size: 1.8rem;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 700;
      &:first-child {
        margin-bottom: 0;
      }
    }
    *:last-child {
      margin-bottom: 0;
    }
    &.pink, &.korall{
      background: var(--color-korall-15);
      color: var(--color-korall-dark);
      ul li::marker {
        color: var(--color-korall-dark);
      }
    }
  }
}

// conference
.vrtx-frontpage-box.partner-logos .vrtx-box-content ul {
  display: flex;
  column-gap: var(--space-m);
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
}

.keynote-speaker {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--space-l);
  h3 {
    grid-column-start: span 2;
  }
  img {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    max-width: 260px;
    width: 220px;
    height: 260px;
    object-fit: cover;
  }
}

// conference ^

// Studier
// Program-status
.vrtx-context-message-box.grid-container {
  margin-bottom: var(--space-m);
  .row {
    padding: 0;
    .content-wrapper {
      padding: var(--space-s);
      background: #FFD465 var(--img-info) no-repeat var(--space-s) center;
      max-width: var(--width-max);
      margin: 0 auto;
      .message-text {
        margin-left: 55px;
      }
    }
  }
}
// Program-status ^
#main {
  #vrtx-fs-studieprogram-facts, #vrtx-fs-emne-facts {
    &.vrtx-facts-container{
      background-color: var(--color-korall-15);
      border-radius: var(--border-radius);
      max-width: var(--width-max);
      margin: var(--space-m) -30px;
      padding: 25px 30px 35px;
      @media (max-width: 750px) {
        margin: var(--space-m) 0px;
      }
      dl {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          row-gap: 20px;
          column-gap: 60px;
          .dg {
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              dt {
                white-space: nowrap;
              }
              ul {
                padding-left: 0;
                li {
                    padding-top: 0;
                    &::marker {
                        color: transparent;
                    }
                }
             }
          }
      }
      h2 {
          display: none;
          font-size: 2.0rem;
          margin-bottom: 0;
      }
      dd, dt {
          display: inline;
          color: var(--color-korall-dark);
      }
      dt {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
      dd {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 22.5px */
        &:after {
          content: "";
        }
      }
    }
  }
}

#vrtx-fs-studieprogram-frontpage {
  h1, #vrtx-fs-studieprogram-right-column, #vrtx-fs-studieprogram-content {
    max-width: var(--width-soft-max);
    margin-left: auto;
    margin-right: auto;
  }

  .studieprogram-plans-list {
    @media (min-width: 800px) {
      column-count: 3;
    }
    ul {
      padding-left: 25px;
    }
  }

  #vrtx-fs-studieprogram-folders {
    margin-bottom: 90px;
  }

  .fs-studieprogram-stories-wrapper, .fs-studieprogram-wrapper {
    background-color: var(--color-varmgr-15);
    width: 100vw;
    margin-left: calc(50% - 50vw - 8px);

    @media (max-width: 800px) {
      margin-left: -15px;
      width: calc(100% + 30px);
    }
    #vrtx-fs-studieprogram-stories, #vrtx-fs-studieprogram-contact {
      max-width: var(--width-total-max);
      margin: var(--space-m) auto;
      padding: var(--space-m) 50px;

      @media (max-width: 800px) {
        margin: var(--space-m) 0;
        padding: var(--space-m) 15px;
      }
    }
  }

  .fs-studieprogram-wrapper {
    #vrtx-fs-studieprogram-contact {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -100px;
      padding-bottom: var(--space-l);
      h2 {
        flex: 100%;
      }
      .image {
        max-width: 150px;
      }
      .info {
        margin-right: var(--space-l);
      }
      @media (max-width: 800px) {
        .info {
          flex: 100%;
          margin-right: 0;
          .contact {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

#vrtx-fs-emne {
  .vrtx-date-info {
    margin-top: var(--space-m);
  }
}

#vrtx-fs-studieprogram-document, #vrtx-fs-emne {
  #vrtx-main-content {
    .vrtx-fs-study-model {
      .course-list {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: calc(var(--space-s) + var(--space-xs));
          margin-top: var(--space-s);
          li {
              position: relative;
              border-top: 1px solid var(--color-primary);
              border-left: 15px solid var(--color-primary);
              border-bottom: 1px solid var(--color-primary);
              background-color: var(--color-aksentfarge1);
              margin-bottom: var(--space-xs);
              padding: 0;
              // &:last-child{
              //     border-bottom: 1px solid var(--color-primary);
              // }
              &:hover, &:focus {
                background-color: var(--color-aquablue);
              }
          }
          li:not(.mandatory){
              .course-code{
                  &:after{
                      content: "\00a0 / Valgbart emne";
                      display: inline-block;
                      color: var(--color-dark);
                  }
              }

              .course-study-points{
                  background: none;
                  color: var(--color-primary);
                  border-left: 1px solid;
                  border-right: 1px solid;
              }
          }
          .course-description{
              padding: 8px 90px 10px 10px;
              display: block;
              .course-code {
                  font-size: 1.6rem;
                  flex-shrink: 0;
                  color: var(--color-dark);
                  width: 72px;
              }
              .course-info {
                  display: block;
              }
              .course-not-linked-info {
                  display: none;
              }
              .course-terms {
                  order: 3;
                  color: var(--color-dark);
                  font-size: 1.6rem;
                  &:before{
                      content: "\00a0 /\00a0";
                  }
              }
          }
          .course-study-points{
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              top: 0;
              width: 80px;
              color: #fff;
              background: var(--color-primary);
              span:first-child{
                  font-weight: 700;
              }
              span:first-child:after {
                  content: "\00a0";
              }
          }
          .course-link {
              text-decoration: none;
              .course-description {
                  &:hover{
                      background-color: var(--color-neutral--light);
                      .course-info{
                          text-decoration: underline;
                      }
                  }
              }
          }
      }
      form[id*="direction-form-"] {
        max-width: fit-content;
        label {
          background-color: var(--color-aksentfarge1);
          width: 100%;
          border-radius: var(--border-radius);
          color: var(--color-dark);
          &::before {
            box-shadow: 0 0 0 2px var(--color-primary);
          }
        }
        input:focus + label {
          outline: 2px solid var(--color-primary);
          outline-offset: 2px;
        }
        input:checked + label {
          background-color: var(--color-primary);
          color: white;
          &::before {
            background-color: var(--color-primary);
          }
        }
      }
    }
  }
}

// Programlisting
body#vrtx-fs-studieprogram-finder {
  h1 {
    margin-bottom: 45px;
    margin-top: 0px;
  }
  #study-program-finder {
    .studies-filter {
      margin-bottom: calc(var(--space-l) + var(--space-xs));
      .text-filter, .inner {
        border-radius: var(--border-radius);
      }
      .button-filter label.selected {
        background-color: var(--color-primary);
      }
      h2 {
        font-weight: 600;
        color: black;
      }
      select {
        border: 1px solid black;
      }
    }
    .studies {
      margin-bottom: var(--space-l);
    }
    .studies-feedback {
      background: none;
      padding: 0;
      .studies-message h2 {
        font-size: 2.2rem;
      }
    }
    .studies-list {
      li {
        margin-bottom: var(--space-xs);
      }
      h3 {
        color: var(--color-link);
        font-size: 2.2rem;
      }
      div:has(span.no-applicants) {
        padding: var(--space-xs) var(--space-s);
        h3 {
          font-size: 1.8rem;
        }
      }
      .no-applicants {
        font-size: 1.5rem;
      }

      a {
        border-radius: var(--border-radius);
      }
      img {
        display: none;
        @media (@screen-medium) {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }
    }
    select {
      height: 2em;
      padding: 4px 10px;
      font-size: 1.8rem;
    }
  }
  #main button.expand-filters {
    color: var(--color-dark);
    align-items: center;
  }

  #vrtx-main-content, #total-main {
    background-color: var(--color-aquablue);
    padding: 0;
  }
}
// Programlisting ^
// Studier ^

// Person
#vrtx-person {
  span#vrtx-hiof-person-change-language-link {
    display: none;
  }
  #vrtx-person-contact-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: var(--space-m);
    border-radius: var(--border-radius);
    background: var(--color-varmgr-15);
    padding: var(--space-s);
    h1 {
      margin-bottom: var(--space-s);
    }
    .vrtx-unit {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: var(--space-m);
    }
    #vrtx-person-contact-info-wrapper {
      display: flex;
      column-gap: 60px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      img.vrtx-person-image {
        max-width: 200px;
        height: auto;
      }
      .vrtx-person-contactinfo {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: var(--space-xs);
        #vrtx-hiof-person-change-language-link {
          display: none;
        }
        .vrtx-label {
          font-weight: 700;
        }
        .vrtx-contact-wrapper {
          display: flex;
          flex: 1;
          column-gap: var(--space-s);
          flex-direction: column;
          @media (@screen-below-medium) {
            flex-direction: column;
          }
          .vrtx-label {
            height: 100%;
          }
          .vrtx-person-contact-line-wrapper {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  .vrtx-projects, .vrtx-groups, #vrtx-publications-wrapper {
    max-width: var(--width-text-max);
  }
}
// Person ^

// steps-guide
#main {
  .steps-guide {
    width: 100%;
    counter-reset: step;
    margin: 20px 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    &>li {
      flex: 1;
      background-color: var(--color-varmgr-15);
      border-radius: 5px;
      align-self: stretch;
      list-style-type: none;
      padding: 15px 20px 20px 20px;
      clip-path: polygon(calc(100% - 10px) 0,calc(100% - 10px) calc(50% - 10px), 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(50% + 10px), 10px 50%, 0 calc(50% - 10px), 0 0);
      &:first-child {
        clip-path: polygon(calc(100% - 10px) 0,calc(100% - 10px) calc(50% - 10px), 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(50% + 10px), 0 calc(50% - 10px), 0 0);
      }
      &:last-child {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 calc(50% + 10px), 10px 50%, 0 calc(50% - 10px), 0 0);
      }

      >a, >span {
        text-decoration: none;
        &::before {
          counter-increment: step;
          content: counter(step);
          display: block;
          text-align: center;
          margin: 0 0 20px calc(50% - 20px);
          background-color: var(--color-primary);
          clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
          //aspect-ratio: 1/1;
          color: white;
          font-weight: 700;
          width: 1.5em;
          height: 1.65em;
        }
      }
      a:focus, a:hover {
        text-decoration: underline;
      }
    }
    ul li {
      list-style-type: disc;
    }
  }
}
// steps-guide ^

//contact component
.vrtx-contact-component {
  background-color: var(--color-aksentfarge1);
  border-radius: var(--border-radius);
  position: relative;
  padding: var(--space-xs);
  min-height: 190px;
  &:after {
      content: "";
      display: table;
      clear: both;
  }
  a:not(.name a):not(.contact a) {
      position: absolute;
      max-height: 190px;
      img {
          border-radius: var(--border-radius);
          width: 150px;
          max-height: 170px;
          height: auto;
          object-fit: cover;
          margin: 0;
          @media (@screen-below-medium) {
              width: 120px;
          }
      }
  }
  a:not(.name a)+.name,
  a:not(.name a)+.name+.title,
  a:not(.name a)+.name+.title+.contact,
  .addresses {
      margin-left: 150px;
      padding-left: 40px;
      @media (@screen-below-medium) {
          margin-left: 120px;
          padding-left: 20px;
      }
  }
  .name {
    font-size: 2.2rem;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .title,
  .contact {
      font-size: 1.6rem;
      display: grid;
      grid-gap: 4px 16px;
      grid-template-columns: max-content;
      dt {
          font-weight: 600;
      }
      dd {
          grid-column-start: 2;
      }
  }
}

.grey-box, #vrtx-fs-studieprogram-frontpage #vrtx-fs-studieprogram-contact { //contact-box on colored background
  .vrtx-contact-component {
    background-color: white;
  }
}
// .vrtx-contact-info ^

.contactcard-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (@screen-below-medium) {
    justify-content: flex-start;
  }
  img.image-left {
    width: 200px;
  }
}

.main {
  ul li {
    &::marker {
      color: var(--color-primary);
      font-size: 2.2rem;
      line-height: 1.8rem;
    }

  }
  .navigation-links ul,
  .vrtx-feed ul,
  .vrtx-share-at-component ul,
  .vrtx-subfolder-menu ul,
  .vrtx-social-list {
    li::before {
      display: none;
    }
  }
}

.vrtx-tags {
  margin-bottom: var(--space-m);

  .title {
    font-weight: 400;
  }
  .vrtx-tags-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: var(--space-s);
    a {
      border-radius: var(--border-radius);
      background: var(--color-sjgrnn-15);
      color: var(--color-link);
      padding: 4px var(--space-xs);
    }
  }
  .tag-separator {
    display: none;
  }
}

#vrtx-tags {
  column-count: 3;
}


// tagview
body#vrtx-tagview {
  .tagged-resources .vrtx-resource {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    .vrtx-image {
      flex-basis: auto;
      max-width: 350px;
    }
  }
}
// tagview ^

// // tagview
// body#vrtx-tagview .tagged-resources .vrtx-resource {
//   display: grid;
//   grid-template-columns: auto 1fr;
//   grid-template-rows: auto auto auto;
//   grid-column-gap: var(--space-m);
//   grid-row-gap: 10px;
//   @media (max-width: 800px) {
//     grid-template-columns: 1fr;
//   }
//   .vrtx-image {
//     grid-row-start: 1;
//      grid-row-end: end;
//   }
//   .vrtx-title, .publish-date, .introduction {
//       grid-column-start: 2;
//   }
// }
// // tagview ^

.three-column-list, .two-column-list {
  .vrtx-box-content {
      ul,
      ol {
          column-gap: var(--space-m);
          column-count: 1;
          li {
              break-inside: avoid;
          }
      }
  }
}
.three-column-list .vrtx-box-content {
  ul, ol {
      @media (@screen-medium) {
          column-count: 3;
      }
  }
}
.two-column-list .vrtx-box-content {
  ul, ol {
      @media (@screen-medium) {
          column-count: 2;
      }
  }
}

.people-three-column-list .vrtx-box-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (@screen-below-medium) {
      grid-template-columns: 1fr;
  }
}
.people-two-column-list .vrtx-box-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--space-m);
  row-gap: var(--space-s);
  @media (@screen-below-medium) {
      grid-template-columns: 1fr;
  }
}

.navigation-links li a::after {
  background: var(--img-button-arrow) no-repeat center;
  background-size: 75%;
  width: 1.5em;
}

#main {
  .row-all-colored .navigation-links,
  .navigation-links {
    margin-bottom: var(--space-s);
    ul {
      gap: 20px;
      li {
        padding-bottom: 0;
        p {
          display: grid;
        }
        a {
          padding-right: 2em;
          font-size: 2.2rem;
          font-weight: 600;
          background-color: var(--color-nav-links);
          border-width: 2px;
          //If text is set in strong, the other text should have weight 400 while strong should have 600
          &:has(strong) {
            font-weight: 400;
            font-size: 1.8rem;
          }
          strong {
            font-weight: 600;
            font-size: 2.2rem;
          }
          &:hover {
            border-color: var(--color-primary);
          }
          &::after {
            background: var(--img-button-arrow) no-repeat center;
            background-size: 100%;
            width: 1em;
            height: 1em;
            top: 1em;
          }
        }
      }
    }
    &.grey-box ul li a {
      background-color: white;
    }
  }
  .row-all-colored .navigation-links ul li a {
    background-color: white;
  }
}

.two-column-view {
  column-count: 2;
}

#vrtx-structured-article, #vrtx-fs-emne, #vrtx-person, #vrtx-structured-project {
  @media (@screen-medium) {
    #vrtx-content {
      margin: 0 0 0 150px;
      max-width: var(--width-soft-max);
    }
  }
}

#vrtx-fs-studieprogram-document, #vrtx-structured-article {
  .vrtx-context-box-linked {
    margin-bottom: var(--space-xs);
    @media (@screen-medium) {
      margin: 0 0 0 150px;
    }
    a {
      color: black;
      font-size: 2.2rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
}

#vrtx-fs-studieprogram-document {
  @media (@screen-medium) {
    .fs-body, .vrtx-context-box-linked {
      max-width: var(--width-text-max);
    }
    #vrtx-content {
      margin: 0 0 0 150px;
      max-width: var(--width-soft-max);
    }
  }
  #toc ul li ul {
    display: none;
  }
}

.vrtx-article-body {
  max-width: var(--width-text-max);
  border-bottom: none;
  margin-bottom: 0;
  margin-left: 0;
}

// Project (x-candidate?)
#vrtx-project-listing, #vrtx-research-group-listing {
  #main {
    ul {
      padding-left: 0;
      font-size: 2.8rem;
      li {
        &::marker {
          color: transparent;
        }
      }
    }
    ul ul {
      padding-left: var(--space-m);
      font-size: 1.8rem;
      list-style-type: disc;
      li::marker {
        color: var(--color-primary);
      }
    }
  }
}
// Project ^


#global-sub-menu {
  display: none;
}
// Footer

#footer-wrapper {
  position: relative;
  background-color: var(--color-varmgr-45);
  color: black;
  padding-top: 130px; // Including space for logo
  padding-bottom: 0;
  font-size: var(--font-size-base);

  // Footer logo
  #footer-logo {
    position: absolute;
    top: 50px;
    width: 250px;
    height: 50px;
    background: url("../images/logos/logo-no-black.svg") no-repeat;
    background-size: contain;
    a {
      display: block;
      text-indent: -100000px;
    }
  }

  // Admin menu
  .vrtx-dropdown-link::after {
    background-image: url("../images/arrow-triangle.svg");
  }

  .vrtx-dropdown-wrapper button {
    font-size: 1.8rem;
  }

  // Bottom background image
  &::after {
    content: "";
    display: block;
    background: var(--img-footer);
    background-size: cover;
    aspect-ratio: 776 / 225; // = svg size/viewbox
    margin-top: 50px;
  }
}


// Article feed, Events feed

.vrtx-article-body .vrtx-feed.with-images img {
  margin: 0;
}
.vrtx-image img {
  aspect-ratio: 16 / 9;
}

.vrtx-resources .vrtx-title {
  font-weight: bold;
  font-size: 3.1rem;
}

// Events listing
#vrtx-event-listing{
  .vrtx-time-and-place-container {
    font-weight: 600;
  }
}

// Events feed
.two-column-feed {
 .vrtx-event-component-item {
    display: flex;
    align-items: center;
    gap: 40px;
    &.with-picture {
      display: revert;
    }
  }
}
.three-column-feed {
  .vrtx-event-component-item {
    display: flex;
    column-gap: var(--space-s);
  }
}

.location::before {
    content: "";
    display: inline-block;
    background: transparent var(--img-location-pin) no-repeat center left;
    width: 15px;
    height: 20px;
    padding-right: 6px;
}

#main {
  .vrtx-date-icon,
  .vrtx-daily-events-date {
    background: #E9F6F4;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    justify-content: center;
    align-items: center;
    width: 98px;
    min-width: 98px;
    height: 114px;
    color: var(--color-link);
    font-size: 3.2rem;
    font-weight: 400;
    .vrtx-date-icon-day {
      font-weight: 400;
    }
    .vrtx-date-icon-month {
      font-size: 2.0rem;
    }
    &:has(.vrtx-date-icon-end) {
      font-size: 2.0rem;
      flex-direction: column;
      .vrtx-date-icon-separator {
        padding-bottom: 0;
        line-height: 1.5;
        height: 1em;
        margin-top: -10px;
      }
      .vrtx-date-icon-start, .vrtx-date-icon-end {
        display: flex;
        flex-direction: row;
        column-gap: 0.2em;
        .vrtx-date-icon-day {
          font-weight: 600;
        }
      }
    }
  }

  .grey-box {
    .vrtx-date-icon, .vrtx-daily-events-date {
      background: white;
    }
  }
}

.vrtx-event-component-item.with-picture {
  margin-top: 20px;
  .vrtx-date-icon {
    margin-top: -30px;
  }
}

.vrtx-feed .item-title, .vrtx-event-component-title.summary {
  font-weight: 600;
  font-size: 2.6rem;
}

#vrtx-blog-listing, #vrtx-article-listing, #vrtx-tagview  {
  h1 {
    margin-top: var(--space-m);
  }
  #breadcrumbs:has(.breadcrumb) + h1 {
    margin-top: 0;
  }

  #vrtx-content, #right-main {
    max-width: var(--width-soft-max);
    padding-left: 50px;
  }
}
// Event-feed ^

// Event
#vrtx-structured-event {
  .vrtx-image img {
    aspect-ratio: 16 / 9;
  }
  .vrtx-time-and-place-container {
    font-size: 2.6rem;
    .time-and-place {
      display: block;
      font-size: 2.2rem;
      font-weight: 600;
    }
    .vrtx-number-of-comments-add-event-container {
      font-size: 1.8rem;
    }
  }
}
// event ^

// Blog
#vrtx-blog-listing #vrtx-content {
  display: flex;
  gap: 40px;
  @media (@screen-below-medium) {
    flex-direction: column;
    padding-left: 0px;
  }
}
#vrtx-blog-listing, #vrtx-structured-article.blog {
  h1 {
    display: none;
  }
  #main .grid-container.full-width-picture-bg {
    margin-bottom: var(--space-m);
    @media (@screen-medium) {
      margin-bottom: var(--space-l);
      .vrtx-frontpage-box-picture {
        img {
          margin-left: calc(50% - 50vw + 7px);
          max-width: 100vw;
          object-fit: cover;
        }
      }
      .vrtx-box-content {
        margin-top: -120px;
        a {
          color: #fff;
        }
      }
    }
    .vrtx-box-content {
      a {
        font-size: 45px;
        font-weight: 600;
        text-decoration: none;
      }
    }
    .row {
      padding: 0;
    }
  }

  .grid-container.blog-about {
    max-width: 100vw;
    width: 100vw;
    margin-left: calc(50% - 50vw - 8px);
    .vrtx-frontpage-box {
      &.img-special-left, &.img-special-right {
        background-color: transparent;
        .vrtx-frontpage-box-picture {
          margin-top: auto;
          margin-bottom: auto;
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    @media (@screen-below-medium) {
      width: 100%;
      margin-left: 0;
      background-color: transparent;
    }
    .row {
      padding-left: 0;
      padding-right: 0;
    }
  }
  div#vrtx-content {
    .vrtx-paging-feed-wrapper {
      margin-bottom: var(--space-m);
    }
  }
  div#vrtx-additional-content {
    width: 270px;
  }
}
// Blog ^

@media print {
  .consent, .consent-settings, #footer-wrapper, #header-tools, #breadcrumbs, .vrtx-context-message-box.grid-container {
    display: none;
  }
  #head-wrapper {
    background-color: transparent;
    .logo {
      background-image: url("../images/logos/logo-no-black.svg");
    }
  }
  #main {
    #vrtx-fs-studieprogram-facts, #vrtx-fs-emne-facts {
      &.vrtx-facts-container {
       background-color: transparent;
     }
    }
  }
}
