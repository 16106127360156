// decorating for subsections such as institutes, faculty, library etc.
body {
  &.subsection, &.fss, &.for-ansatte {
    &.the-frontpage #main::before {
      background-image: none;
      height: auto;
    }

    #main-menu {
      background-color: var(--color-avd);
      * {
        color: black;
      }
    }

    #head-wrapper {
      padding: 65px 0 25px 0;
      background-color: var(--color-avd);
      color: black;
      &:has(.white-header) {
        a.logo-hiof {
          background-image: url("../images/logos/logo-en.svg");
          display: block;
          width: 250px;
          height: var(--space-l);
        }
        background-color: transparent;
      }
      &:has(.no-banner-top) {
        padding-top: 25px;
      }
      &:has(.white-header) + #main-menu {
        background-color: white;
      }
      .main-actor-and-affiliations {
        display: flex;
        min-height: 87px;
        flex-direction: column;
        gap: 10px;
        .title {
          font-size: 3.1rem;
          font-weight: 600;
          text-transform: uppercase;
        }
        .partners {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      .parent,.parent-green {
        background-color: transparent;
        display: flex;
        align-items: center;
        margin-left: calc(50% - 50vw + 8px);
        height: 50px;
        width: calc(100vw - 15px);
        position: absolute;
        top: -65px;

        .inner {
          margin-right: auto;
          margin-left: auto;
          padding-right: 50px;
          padding-left: 50px;
          max-width: var(--width-total-max);
          width: 100%;
          @media (@screen-below-medium) {
            padding-left: 15px;
            padding-right: 15px;
          }

          a {
            height: 33px;
            span {
              position: absolute;
              left: -100000px;
            }
          }
        }
      }
      .parent-green {
        background-color: var(--color-primary);
      }
      #section {
        margin-right: auto;
        display: flex;
        flex-direction: column;
      }

      #header {
        .subsection-title {
          font-size: 3.1rem;
          margin-right: auto;
          text-decoration: none;
          font-weight: 600;
          margin-left: -1px;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
        .logo {
          display: flex;
          background: none;
          width: 100%;
          flex-direction: column;
          row-gap: 10px;
          a.title-name {
            text-indent: 0px;
            margin-right: auto;
            font-size: 3.1rem;
            font-weight: 600;
            text-decoration: none;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
          a.subtitle {
            text-indent: 0;
            font-size: 3.6rem;
            font-weight: 600;
          }

          // conference
          .subtitle-partners {
            align-self: start;
            display: grid;
            grid-template-columns: auto 1fr;
            a {
              max-height: 40px;
              height: 40px;
            }
          }
          // conference ^

        }

        #header-tools {
          background-color: transparent;
          padding: 0;
          margin-bottom: auto;
          margin-top: auto;
          .toggle-search::before {
            background-image: var(--img-search-inv);
          }
          .sidebar-menu-toggle::before {
            background-image: var(--img-menu-inv);
          }
          .search-form form {
            input {
              border: 2px solid var(--color-primary);
              color: var(--color-primary);
            }
            button {
              background: var(--color-primary);
              color: white;
            }
          }
        }
      }
    }
    
    #header-search-form {
      input[type="text"]::placeholder {
        color: black;
      }
    }

    &.en, &.no  {
      a.logo-hiof {
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        width: 250px;
        height: 33px;
      }
      a[class^="logo"] span {
        position: absolute;
        left: -100000px;
      }
    }

    &.en {
      a.logo-hiof {
        background-image: url("../images/logos/logo-en-black.svg");
      }
    }

    &.no {
      a.logo-hiof {
        background-image: url("../images/logos/logo-no-black.svg");
      }
    }
    .grid-container.row-all-colored {
      background: var(--color-avd);
    }
    &.ai-hub {
      #context-sub-title {
        display: none;
      }
    }
}
}
