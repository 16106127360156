// Source Sans Pro

// Italic

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  //src: url(https://fonts.gstatic.com/s/sourcesanspro/v19/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkids18S0xR41.woff2) format('woff2');
  src: url("../fonts/sourcesanspro-light-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/sourcesanspro-regular-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/sourcesanspro-semibold-italic.woff2") format('woff2');
}
/* Excluding 700 to make 600 default bold weight
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/sourcesanspro-bold-italic.woff2") format('woff2');
}
*/

// Normal/non-italic

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/sourcesanspro-light.woff2") format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/sourcesanspro-regular.woff2") format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/sourcesanspro-semibold.woff2") format('woff2');
}
/* Excluding 700 to make 600 default bold weight
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/sourcesanspro-bold.woff2") format('woff2');
}
*/
